





































































































































import { FormMetadataCreationTs } from './FormMetadataCreationTs';
export default class FormMetadataCreation extends FormMetadataCreationTs {}
