









































































// @ts-ignore
import { FormExtendNamespaceDurationTransactionTs } from './FormExtendNamespaceDurationTransactionTs';
export default class FormExtendNamespaceDurationTransaction extends FormExtendNamespaceDurationTransactionTs {}
