




















































































































// @ts-ignore
import { FormMosaicSupplyChangeTransactionTs } from './FormMosaicSupplyChangeTransactionTs';
export default class FormMosaicSupplyChangeTransaction extends FormMosaicSupplyChangeTransactionTs {}
