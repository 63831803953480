


































import { ModalMetadataDisplayTs } from './ModalMetadataDisplayTs';
export default class ModalMetadataDisplay extends ModalMetadataDisplayTs {}
