







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ButtonAdd extends Vue {
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: undefined }) title: boolean;
    @Prop({ default: undefined }) size: string;
}
