























































































































































import { TableDisplayTs } from './TableDisplayTs';
export default class TableDisplay extends TableDisplayTs {}
