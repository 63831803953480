





















import { ModalMetadataUpdateTs } from './ModalMetadataUpdateTs';
export default class ModalMetadataUpdate extends ModalMetadataUpdateTs {}
