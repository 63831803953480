




































































































import { FormAliasTransactionTs } from './FormAliasTransactionTs';
export default class FormAliasTransaction extends FormAliasTransactionTs {}
