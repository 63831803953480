













import { SupplyInputTs } from './SupplyInputTs';
export default class SupplyInput extends SupplyInputTs {}
