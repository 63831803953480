









import { MosaicListPageTs } from './MosaicListPageTs';
export default class MosaicListPage extends MosaicListPageTs {}
