


































import { NamespaceNameInputTs } from './NamespaceNameInputTs';
export default class NamespaceNameInput extends NamespaceNameInputTs {}
