














import { RentalFeeTs } from './RentalFeeTs';
export default class RentalFee extends RentalFeeTs {}
