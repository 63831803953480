





















import { DivisibilityInputTs } from './DivisibilityInputTs';
export default class DivisibilityInput extends DivisibilityInputTs {}
