


















import { DurationInputTs } from './DurationInputTs';
export default class DurationInput extends DurationInputTs {}
