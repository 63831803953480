var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormWrapper',[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-container",attrs:{"onsubmit":"event.preventDefault()"}},[(_vm.isAggregate)?_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('mosaic'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{attrs:{"vid":"newDuration","name":_vm.$t('mosaic'),"rules":"required","immediate":true,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('MosaicSelector',{attrs:{"mosaic-hex-ids":_vm.ownedTargetHexIds,"default-mosaic":"firstInList","required":""},model:{value:(_vm.formItems.mosaicHexId),callback:function ($$v) {_vm.$set(_vm.formItems, "mosaicHexId", $$v)},expression:"formItems.mosaicHexId"}})],1)]}}],null,true)})]},proxy:true}],null,true)}):_vm._e(),_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_supply_direction'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('div',{staticClass:"select-container"},[_c('ValidationProvider',{attrs:{"vid":"newDuration","name":_vm.$t('direction'),"rules":"required","immediate":true,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('Select',{staticClass:"select-size select-style",model:{value:(_vm.formItems.action),callback:function ($$v) {_vm.$set(_vm.formItems, "action", $$v)},expression:"formItems.action"}},[_c('Option',{attrs:{"value":_vm.MosaicSupplyChangeAction.Increase}},[_vm._v(" "+_vm._s(_vm.$t('increase'))+" ")]),_c('Option',{attrs:{"value":_vm.MosaicSupplyChangeAction.Decrease}},[_vm._v(" "+_vm._s(_vm.$t('decrease'))+" ")])],1)],1)]}}],null,true)})],1)]},proxy:true}],null,true)}),_c('SupplyInput',{attrs:{"label":"form_label_supply_delta"},model:{value:(_vm.formItems.delta),callback:function ($$v) {_vm.$set(_vm.formItems, "delta", $$v)},expression:"formItems.delta"}}),_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_current_supply'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('div',{staticClass:"row-left-message"},[(_vm.currentMosaicInfo)?_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.$t('relative'))+": "+_vm._s(_vm.currentMosaicRelativeSupply)+" ("+_vm._s(_vm.$t('absolute'))+": "+_vm._s(_vm.currentMosaicInfo.supply.toLocaleString())+") ")]):_vm._e()])]},proxy:true}],null,true)}),_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_new_supply'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{attrs:{"vid":"newDuration","name":_vm.$t('form_label_new_absolute_supply'),"rules":_vm.validationRules.supply,"immediate":true,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model",value:(_vm.newMosaicAbsoluteSupply),expression:"newMosaicAbsoluteSupply"}],domProps:{"value":(_vm.newMosaicAbsoluteSupply)},on:{"change":validate,"input":function($event){if($event.target.composing){ return; }_vm.newMosaicAbsoluteSupply=$event.target.value}}}),_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('div',{staticClass:"input-size row-left-message"},[_c('span',{class:['pl-2', errors.length ? 'red' : '']},[_vm._v(" "+_vm._s(_vm.$t('relative'))+": "+_vm._s(_vm.newMosaicRelativeSupply || '')+" ("+_vm._s(_vm.$t('absolute'))+": "+_vm._s(_vm.newMosaicAbsoluteSupply && _vm.newMosaicAbsoluteSupply.toLocaleString())+") ")])])])]}}],null,true)})]},proxy:true}],null,true)}),(!_vm.isAggregate)?_c('MaxFeeAndSubmit',{on:{"button-clicked":function($event){return handleSubmit(_vm.onSubmit)}},model:{value:(_vm.formItems.maxFee),callback:function ($$v) {_vm.$set(_vm.formItems, "maxFee", $$v)},expression:"formItems.maxFee"}}):(!_vm.hideSave)?_c('div',{staticClass:"ml-2",staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"save-button centered-button button-style inverted-button",attrs:{"type":"submit","disabled":_vm.currentAccount.isMultisig || !_vm.formItems.mosaicHexId || _vm.formItems.action == null},on:{"click":_vm.emitToAggregate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])]):_vm._e()],1)]}}])}),(_vm.hasConfirmationModal)?_c('ModalTransactionConfirmation',{attrs:{"command":_vm.command,"visible":_vm.hasConfirmationModal},on:{"success":_vm.onConfirmationSuccess,"error":_vm.onConfirmationError,"close":_vm.onConfirmationCancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }