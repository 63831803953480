import { render, staticRenderFns } from "./ModalMetadataDisplay.vue?vue&type=template&id=6466ad78&scoped=true&"
import script from "./ModalMetadataDisplay.vue?vue&type=script&lang=ts&"
export * from "./ModalMetadataDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./ModalMetadataDisplay.vue?vue&type=style&index=0&id=6466ad78&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6466ad78",
  null
  
)

export default component.exports