


































import { NamespaceSelectorTs } from './NamespaceSelectorTs';

export default class NamespaceSelector extends NamespaceSelectorTs {}
